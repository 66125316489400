<script>
import { mapActions } from "vuex";
import axios from 'axios'
import { ref, computed } from "vue";
import { useCookies } from "vue3-cookies";
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";

export default {
  name: "userSearchModel",
  emits: ["getParent"], 
  props: [
    'ID',
    'type',
    'data',
  ],
  components:{
   
  },
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();   
    let tk = 'Bearer '+cookies.get('tk')
    let searchTerm = ref('')
    let qSearch = ''
    let countries = []


    const fullSearch = async () =>
    {
      axios.get(`${serverConfig.EP}customers/search/full/text`,
        { 
          params: { 
            q: qSearch,
           
          },
          headers: { 'authorization':tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          countries = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }


    const searchCountries = computed(() => {
      if (searchTerm.value === '') {
        return []
      }
      let matches = 0
      return countries.filter(country => {
        if (country.name.toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
          matches++
          return country
        }
      })
    });
    const selectCountry = (country) => {
      selectedCountry.value = country
      searchTerm.value = ''
    }
    let selectedCountry = ref('')

    return { 
      fullSearch,
      searchTerm,
      searchCountries,
      selectCountry,
      selectedCountry,
      active, 
      toggleActive, 
      tk 
    };
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      fileStorage:[],
      uploadValue:0,
      fileList:null,
      fileData:[],
      file:'',
      bucket:'',
      dataEdit:{},
      qSearch:'',
      resultsList:[],
      
    }
  },
  mounted(){
   

  },
  methods:{
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    async fullSearch2(){
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}users/search/full/text`,
        { 
          params: { 
            q: this.qSearch,
          },
          headers: { 'authorization':this.tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          this.resultsList = response.data
        }
      })
    },
    selectCustomer(params){
      this.updateItem(params)
    },
    updateItem(params){
        let data = {}
        data.consultantID = params._id
        data.consultantDetails = params
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}projects/${this.ID}`, data, {headers: { authorization:this.tk}} )
          .then(()=>{
            this.$emit('getParent')
            this.closeModal()
          })
      },
    openModal(){
        document.getElementById("editmodal").click();
    },
    closeModal(){
      document.getElementById("close-modal").click();
    },  
  }
};
</script>
<template>
  <div>
    <a href="#searchUsrModal" data-bs-toggle="modal" class="" title="Modifica utente">
      <i class="mdi mdi-account-search"></i> 
    </a>
    <div class="modal fade" id="searchUsrModal" data-bs-backdrop="static"  tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
                    <div class="modal-header bg-dark p-3 pl-5 pr-5">
                      <h5  id="searchUsrModalLabel">
                      Associa anagrafica utente al documento
                      </h5>
                      <button type="button" class="btn-clos" data-bs-dismiss="modal" aria-label="Close"
                        id="close-modal" style="">
                      
                      </button>
                    </div>
                    <form action="#" id="addform">
                      <div class="modal-body">
                        <input type="hidden" id="id-field" />
                        <div class="mb-3" id="modal-id" style="display: none">
                          <label for="id-field1" class="form-label">ID</label>
                          <input type="text" id="edtidfield1" class="form-control" placeholder="ID" readonly />
                        </div>
                        <div class="row">
                          <div class="col-md-12 col-lg-12">
                            <div class="mb-3">
                            
                                <input
                                  type="text"
                                  id="search"
                                  v-model="qSearch"
                                  placeholder="Digita qui termine di ricerca..."
                                  class="form-control-search p-3 mb-0.5 w-full border border-blue-300 rounded"
                                  @input="fullSearch2()"
                                >             
                            </div>
                          </div>
                          <div class="col-md-12 col-lg-12">
                            <div class="mb-3">
                              <ul
                                v-if="resultsList.length"
                                class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
                              >
                                <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
                                  Mostra {{ searchCountries.length }} di {{ resultsList.length }} risultati
                                </li>
                                <li
                                    v-for="item in resultsList"
                                    :key="item._id"
                                    @click="selectCustomer(item), $emit('getParent')"
                                    class="cursor-pointer hover:bg-gray-100 p-1"
                                >
                                  <template v-if="item.firstName">
                                    <strong>{{ item.firstName }}</strong>
                                  </template>
                                  <template v-if="item.lastName">
                                      &nbsp; <strong>{{ item.lastName }}</strong>
                                  </template>
                                  <template v-if="item.ragSoc!=''">
                                    &nbsp;  <strong>{{ item.ragSoc }}</strong>
                                  </template>
                                  <template v-if="item.email!=''">
                                    &nbsp;  (<strong>{{ item.email }}</strong>)
                                  </template>
                                </li>
                              </ul>

                              <p
                                v-if="selectedCountry"
                                class="text-lg pt-2 absolute"
                              >
                                You have selected: <span class="font-semibold">{{ selectedCountry }}</span>
                              </p>
                            </div>
                          </div>
                        </div>
              </div>
            </form>
          </div>
        </div>
    </div>
  </div>
</template>



