<script>
import moment from 'moment'
export default {
    name:'timelineTasksWidget',
    props:[
        'type',
        'data'
    ],
    methods:{
        convertDatetime(date){
            moment.locale('it')
            return moment(date).format('LL')
        },
        checkEvenOrOdd(number){
            if (number % 2 === 0) {
                return 'even'
            } else {
                return 'odd'
            }
        },
        checkType(task){

            if (task == 'task-cantiere') {
                return '<i class="mdi mdi-account-hard-hat"></i>'
            } else if (task == 'task-assistenza')
            {
                return '<i class="ri-customer-service-2-line"></i>'
            } else {
                return '<i class="ri-quill-pen-line"></i>'
            }

        }
    }
}
</script>
<template>
    <div class="row">
        <div class="col-lg-12">
            <div>
                <h4>Timeline</h4>
                <div class="row card-body background-white ml-2 mr-2">
                    <div class="col">
                        <i class="icon ri-stack-line"></i> Inizio Lavorazione
                    </div>
                    <div class="col">
                        <i class="icon ri-customer-service-2-line"></i> Assistenza
                    </div>
                    <div class="col">
                        <i class="icon ri-ruler-2-line"></i> Sopralluogo
                    </div>
                    <div class="col">
                        <i class="icon mdi mdi-account-hard-hat"></i> Intervento in cantiere
                    </div>
                    <div class="col">
                        <i class="icon ri-quill-pen-line"></i> Altro
                    </div>
                </div>
                <div class="timeline">
                   
                    <div  :class="{
                        'timeline-item left': checkEvenOrOdd(index) == 'even',
                        'timeline-item right': checkEvenOrOdd(index) == 'odd',
                        }" :key="index" v-for="(item, index) in data" >
                       
                        
                        <template v-if="index == 0">
                            <i class="icon ri-stack-line"></i>
                            
                        </template>
                        <template v-else>
                            <template v-if="item.task_type=='task-cantiere'">
                                <i class="icon mdi mdi-account-hard-hat"></i>
                            </template>
                            <template v-else-if="item.task_type=='task-assistenza'">
                                <i class="icon ri-customer-service-2-line"></i>
                            </template>
                            <template v-else-if="item.task_type=='task-sopralluogo'">
                                <i class="icon ri-ruler-2-line"></i>
                            </template>
                            <template v-else>
                                <i class="icon ri-quill-pen-line"></i>
                            </template>
                        </template>
                        
                        <div class="date"> {{ convertDatetime(item.startStr) }}</div>
                        <div class="content">
                            <h5>{{item.name}}</h5>
                            <span>Dal {{ convertDatetime(item.startStr) }} al {{ convertDatetime(item.endStr) }}</span>
                            <p class="text-muted">{{ item.details }}</p>
                            <div class="avatar-group mb-2">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
           

        </div>
    </div>
</template>