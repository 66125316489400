<script>
import GoogleMap from 'vue-google-maps-ui'
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';

export default {
  name:'gcloud',
  props:[
    'data',
    'type'
  ],
  setup() {
    const apiKey = 'AIzaSyBF1wXkNz_d_EQouSrkwBX_1EL3b6Gz3Ps'
    let tk = 'Bearer '+localStorage.getItem('tk')
    return { tk,apiKey };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        apiMaps:serverConfig.GOOGLE_API_KEY,
        locationObj:{ lat: 40.7286362, lng: 8.5248793 },
        item:{},
        lati:0,
        longi:0,
        place_id:'',
        geoData:false,
    }
  },
  components: {
    GoogleMap
  },
  mounted(){
    this.item = this.data
    if(this.item.latitude){
      this.locationObj.lat = parseFloat(this.item.latitude) 
    }
    if(this.item.longitude){
      this.locationObj.lng = parseFloat(this.item.longitude) 
    }
  },
  methods: {
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                    this.$router.push('/')            
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    getItem() {
      this.axiosInterceptor()
      
    },
    getMarkers (markers) {
          Object.keys(markers).forEach(key => {
            const marker = markers[key]
            const { lat, lng } = marker.center
            console.log({
              marker,
              radius: marker.radius,
              center: { lat: lat(), lng: lng() }
            })
          })
    },
    refreshCoord () {
      this.locationObj.lat =  this.item.latitude 
        this.locationObj.lng = this.item.longitude
    },
    getAddress (coordinate) {
     
      if (coordinate){
        this.item.address = coordinate.address 
        this.item.latitude =  coordinate.info.coords.lat
        this.item.longitude = coordinate.info.coords.lng
        this.locationObj.lat = coordinate.info.coords.lat
        this.locationObj.lng = coordinate.info.coords.lng
        this.lati = coordinate.info.coords.lat 
        this.longi = coordinate.info.coords.lng
        this.place_id = coordinate.info.place_id
        if (this.place_id) {
          this.getGeocode()
        }
        
      }
    },
    geoCodeFilter(data){
      /**
       * 
       *
       * country:{ type: String},
         zipCode:{ type: String},
         city:{ type: String},
         address:{ type: String},
         address_number:{ type: String},
         province:{ type: String},
         region:{ type: String},
       */
      for (let row of data.address_components) {
        
        if (row.types[0]=='administrative_area_level_3') {
          this.item.city = row.long_name
        }
        if (row.types[0]=='administrative_area_level_2') {
          this.item.province = row.short_name
        }
        if (row.types[0]=='administrative_area_level_1') {
          this.item.region = row.long_name
        }
        if (row.types[0]=='country') {
          this.item.region = row.short_name
        }
        if (row.types[0]=='postal_code') {
          this.item.zipCode = row.short_name
        }
       
      }
      this.item.place_id = data.place_id
      this.item.address = data.formatted_address
      this.item.latitude = data.geometry.location.lat 
      this.item.longitude = data.geometry.location.lng
      this.locationObj.lat = data.geometry.location.lat 
      this.locationObj.lng = data.geometry.location.lng 
      this.updateW()
    },
    getGeocode(){
     this.axiosInterceptor()
     axios.get(`${this.UrlServer}gcloud/geocode/${this.place_id}`, {headers: { authorization:this.tk}})
     .then((response) => {
        if (response.data) {
          this.geoData = response.data
          if (response.data.address_components) {
            this.geoCodeFilter(response.data)
           
          }
        }
        
     })
   }, 
   getGeoLocate(){
     this.axiosInterceptor()
     axios.get(`${this.UrlServer}gcloud/geocode-address`, {
      params:{
        address:this.item.address,
      },
      headers: { authorization:this.tk}})
     .then((response) => {
        if (response.data) {
          this.geoData = response.data
          if (response.data.address_components) {
            this.geoCodeFilter(response.data)
            location.reload()
          }
        }
        
     })
   }, 
   getRevGeoLocate(par_lat,par_lng){
     this.axiosInterceptor()
     axios.get(`${this.UrlServer}gcloud/reverse-latlng`, {
      params:{
        lat:par_lat,
        lng:par_lng,
      },
      headers: { authorization:this.tk}})
     .then((response) => {
        if (response.data) {
          this.geoData = response.data
          if (response.data.address_components) {
            this.geoCodeFilter(response.data.address_components)
          }
        }
        
     })
   }, 
    updateW(){
     this.axiosInterceptor()
     axios.put(`${this.UrlServer}worksites/${this.data._id}`, this.item, {headers: { authorization:this.tk}})
     .then((response) => {
        if (response.data) {
          console.log(response.data)
        }
        
     })
   }, 
  },

}
</script>
<template>
                  <div class="card-body mb-20">
                            <div class="row mb-50 pl-2">
                                <div class="col-md-12 col-lg-4 mb-20">
                                  <h4>Luogo di installazione</h4>
                                </div>
                            </div>
                            <div class="row mb-50 pl-2">
                                <div class="col-md-12 col-lg-3">
                                  <label for="code-field" class="form-label pr-10"><strong>Indirizzo</strong></label>
                                  <i class="bx bxs-home pr-10"></i>
                                  <input type="text" class="form-control" id="name" v-model="item.address"  @blur="getGeoLocate">                                	
                                </div>
                                <div class="col-md-12 col-lg-2">
                                  <label for="code-field" class="form-label pr-10"><strong>Città</strong></label>
                                  <i class="bx bxs-city "></i><span class="input-group-text " >{{ item.city }}</span>
                                </div>
                                <div class="col-md-12 col-lg-2">
                                  <label for="code-field" class="form-label pr-10"><strong>C.A.P</strong></label>
                                  <i class="bx bx-current-location "></i><span class="input-group-text " >{{ item.zipCode }}</span>
                                </div>
                                <div class="col-md-12 col-lg-2">
                                  <label for="code-field" class="form-label pr-10"><strong>Latitudine</strong></label>
                                  <i class="ri-map-pin-fill "></i><span class="input-group-text " >{{ item.latitude }}</span>
                                </div>
                                <div class="col-md-12 col-lg-2">
                                  <label for="code-field" class="form-label pr-10"><strong>Longitudine</strong></label>
                                    
                                  <i class="ri-map-pin-line "></i><span class="input-group-text " >{{ item.longitude }}</span>

                                </div>
                            </div>
                            <div class="row mt-50 pl-2">
                                <div class="col-md-12 col-lg-12">
                                  <div class="card">
                                    <div class="card-body">
                                   
                                    <GoogleMap 
                                      :api-key="apiKey"
                                      language="it"
                                      :autocomplete="true"
                                      :location="locationObj"
                                     
                                      @change-address="getAddress"
                                      :map-style-option="{ borderRadius: '9px', border: '4px solid orange' }"
                                      />
                                  </div>
                                  </div>                                
                                </div>
                            </div>
                           


                  </div>
</template>